// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/fcA9jCeSA";

const serializationHash = "framer-QDwIy"

const variantClassNames = {IrZa1WwCB: "framer-v-110wyxs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tag1, width, ...props}) => { return {...props, gllQTyKg2: tag1 ?? props.gllQTyKg2 ?? "Branding"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tag1?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gllQTyKg2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "IrZa1WwCB", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-110wyxs", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"IrZa1WwCB"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c1752f62-a05f-4a70-87a2-81975cf7421c, rgb(0, 0, 0))", borderBottomLeftRadius: 70, borderBottomRightRadius: 70, borderTopLeftRadius: 70, borderTopRightRadius: 70, boxShadow: "0px 0.6369534988189116px 0.6369534988189116px -0.1875px rgba(0, 0, 0, 0.04269), 0px 1.9316049144836143px 1.9316049144836143px -0.375px rgba(0, 0, 0, 0.05628), 0px 5.106122817187569px 5.106122817187569px -0.5625px rgba(0, 0, 0, 0.08961), 0px 16px 16px -0.75px rgba(0, 0, 0, 0.204)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h6 className={"framer-styles-preset-rs8dj4"} data-styles-preset={"fcA9jCeSA"} style={{"--framer-text-color": "var(--extracted-1w1cjl5, var(--token-9e556e9b-3f7c-4df8-84cb-696985c1d319, rgb(255, 255, 255)))"}}>Branding</motion.h6></React.Fragment>} className={"framer-ao62kl"} data-framer-name={"Tag Name"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"RU1772f4A"} style={{"--extracted-1w1cjl5": "var(--token-9e556e9b-3f7c-4df8-84cb-696985c1d319, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={gllQTyKg2} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QDwIy.framer-1uftbz4, .framer-QDwIy .framer-1uftbz4 { display: block; }", ".framer-QDwIy.framer-110wyxs { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 24px 15px 24px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-QDwIy .framer-ao62kl { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QDwIy.framer-110wyxs { gap: 0px; } .framer-QDwIy.framer-110wyxs > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QDwIy.framer-110wyxs > :first-child { margin-left: 0px; } .framer-QDwIy.framer-110wyxs > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 126
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"gllQTyKg2":"tag1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercKO5XMGAn: React.ComponentType<Props> = withCSS(Component, css, "framer-QDwIy") as typeof Component;
export default FramercKO5XMGAn;

FramercKO5XMGAn.displayName = "Tag";

FramercKO5XMGAn.defaultProps = {height: 49, width: 126};

addPropertyControls(FramercKO5XMGAn, {gllQTyKg2: {defaultValue: "Branding", title: "Tag 1", type: ControlType.String}} as any)

addFonts(FramercKO5XMGAn, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})